<script setup>
</script>

<template>
  <teleport to="head">
    <component
        :is="'script'">window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 10875504,playbookUuid: '74a2f30f-5663-4144-95a3-8f975e3d5689',version: 2};(function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();
    </component>
    <component :is="'script'" defer src="https://leadbooster-chat.pipedrive.com/assets/loader.js"></component>
  </teleport>
</template>
